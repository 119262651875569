import * as React from "react";
import styled, { createGlobalStyle } from "styled-components";

import "reset-css";
import "../index.css";

import bgLarge from "../images/bg-large.jpg";
import bgSmall from "../images/bg-small.jpg";
import logo from "../images/logo.svg";
// import facebook from "../images/facebook.svg";
// import instagram from "../images/instagram.svg";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
`;

const Main = styled.main`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  background-image: url("${bgSmall}");
  background-size: cover;

  @media (min-width: 700px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background-position: center;
    background-image: url("${bgLarge}");
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100%;
  text-align: center;
  max-width: 460px;
  padding: 20px;
  margin: auto;

  @media (min-width: 700px) {
    display: inline-block;
    max-width: 400px;
    min-height: auto;
    margin: 50px 120px;
    padding: 0;
  }
`;

const Logo = styled.img`
  width: 240px;
  opacity: 0.9;
  margin-left: 17px;
`;

const Title = styled.h1`
  font-family: "TudorVictors", georgia, serif;
  color: #f8f7f5;
  font-weight: normal;
  word-wrap: break-word;
  font-kerning: normal;
  text-transform: uppercase;
  font-size: 70px;
  padding-top: 50px;

  @media (min-width: 700px) {
    padding-top: 80px;
  }
`;

const Slogan = styled.h1`
  font-family: "TudorVictors", georgia, serif;
  color: #f8f7f5;
  font-weight: normal;
  word-wrap: break-word;
  font-kerning: normal;
  text-transform: uppercase;
  color: #945a32;
  font-size: 30px;
  /* padding: 25px 0; */
  padding-top: 25px;

  @media (min-width: 700px) {
    font-size: 37px;
  }
`;

const Input = styled.input`
  border: 0;
  font-size: 16px;
  display: block;
  width: 100%;
  padding: 14px;
  border-radius: 3px;
  margin: 15px 0;
`;

const Button = styled.button`
  font-family: "TudorVictors", georgia, serif;
  color: #f8f7f5;
  font-weight: normal;
  word-wrap: break-word;
  font-kerning: normal;
  text-transform: uppercase;
  background: #371710;
  border: 0;
  font-size: 24px;
  padding: 12px;
  display: block;
  width: 100%;
  border-radius: 3px;
`;

// const SocialMedia = styled.ul`
//   display: inline-flex;
// `;

// const SocialMediaIcon = styled.img`
//   width: 30px;
//   margin: 15px;
// `;

const IndexPage = () => {
  return (
    <Main>
      <title>Battle Warrior Coffee</title>
      <GlobalStyle />

      <Container>
        <Logo src={logo} />

        <div>
          <Title>Thank You</Title>

          <p>You will hear from us soon!</p>

          <Slogan>Keep the battle strong</Slogan>

          {/* <SocialMedia>
            <li>
              <a href="#">
                <SocialMediaIcon src={facebook} />
              </a>
            </li>
            <li>
              <a href="#">
                <SocialMediaIcon src={instagram} />
              </a>
            </li>
          </SocialMedia> */}
        </div>
      </Container>
    </Main>
  );
};

export default IndexPage;
